import React from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
  divideContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(3, 0),
  },
  divider: {
    width: 'calc(100% - 32px)',
    marginLeft: theme.spacing(1),
  },

  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  div: {
    flexGrow: 1,
  },
  or: {
    margin: theme.spacing(0, 1),
    color: '#EBEBEB',
  },
}))

const DividerOr = ({ className }) => {
  const { classes, cx } = useStyles()
  const { t } = useTranslation('common')

  return (
    <div className={classes.root}>
      <Divider className={classes.div} />
      <Typography className={classes.or} variant='button' color='textSecondary'>
        {t('or')}
      </Typography>
      <Divider className={classes.div} />
    </div>
  )

  return (
    <div className={cx(classes.divideContainer, className)}>
      <Typography variant='caption' color='textSecondary'>
        <b>{t('OR')}</b>
      </Typography>
      <Divider className={classes.divider} />
    </div>
  )
}

export default DividerOr
