import React from 'react'
import { makeStyles } from 'tss-react/mui'
import Paper from '@mui/material/Paper'
import { useMediaQuery, useTheme } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    maxWidth: 420,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: 0,
    right: 0,
    margin: theme.spacing(0, 'auto', 0, 'auto'),
    transform: 'translateY(-50%)',
  },
  fullScreen: {
    top: 0,
    transform: 'none',
    borderRadius: 0,
    maxWidth: 'none',
    justifyContent: 'center',
    bottom: 0,
  },
}))

const LargeCentralPaper = ({ children, className }) => {
  const { classes, cx } = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.up('md'))
  return (
    <Paper
      className={cx({
        [className]: true,
        [classes.root]: true,
        [classes.fullScreen]: !fullScreen,
      })}
      elevation={24}
    >
      {children}
    </Paper>
  )
}

export default LargeCentralPaper
