import { useState } from 'react'
import { GoogleLogin } from '@react-oauth/google'
import { useDispatch } from 'react-redux'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import BashButton, {
  BashButtonType,
} from '../../shared-components/buttons/BashButton'
import { useTheme } from '@mui/material'

const useContinueButtonsStyles = makeStyles()((theme) => ({
  container: {
    position: 'relative',
    '& iframe': {
      colorScheme: 'normal',
    },
  },
  googleButton: {
    backgroundColor: theme.palette.grey[300],
    marginBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.grey[500],
    },
  },
  loadingButton: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
}))

export default function GoogleSignInButton({
  organisation,
  width,
  signIn3rdParty,
}) {
  const [submittingGoogle, setSubmittingGoogle] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const { classes } = useContinueButtonsStyles()
  const router = useRouter()
  const theme = useTheme()

  return (
    <div className={classes.container}>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
          setSubmittingGoogle(true)
          signIn3rdParty(
            credentialResponse.credential,
            'Google',
            timeZone,
            organisation,
            router,
          )
        }}
        size='large'
        theme={theme.mode === 'dark' ? 'filled_black' : 'outline'}
        shape='pill'
        width={width}
        text='continue_with'
        onError={() => {
          setSubmittingGoogle(false)
          console.log('Google login Failed')
        }}
      />

      {submittingGoogle && (
        <BashButton
          className={classes.loadingButton}
          type={BashButtonType.WHITE_GREY_BORDER}
          loading
          onClick={null}
        >
          Google
        </BashButton>
      )}
    </div>
  )
}
