import { makeStyles } from 'tss-react/mui'
import React, { useEffect, useRef, useState } from 'react'

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    gap: 8,
    justifyContent: 'center',
  },
  input: {
    color: theme.palette.text.primary,
    width: 48,
    height: 48,
    background: theme.palette.grey[150],
    borderRadius: 8,
    borderColor: theme.palette.grey[150],
    boxShadow: 'none',
    textAlign: 'center',
    fontSize: 24,
    borderStyle: 'solid',
    fontFamily:
      '-apple-system, "BlinkMacSystemFont", "Roboto", "Arial", sans-serif',
  },
}))

export default function EnterCode({ callback, reset, isLoading }) {
  const [code, setCode] = useState('')
  const { classes } = useStyles()

  // Refs to control each digit input element
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]

  // Reset all inputs and clear state
  const resetCode = () => {
    inputRefs.forEach((ref) => {
      ref.current.value = ''
    })
    inputRefs[0].current.focus()
    setCode('')
  }

  // Call our callback when code = 6 chars
  useEffect(() => {
    if (code.length === 6) {
      if (typeof callback === 'function') callback(code)
      //   resetCode()
    }
  }, [code]) //eslint-disable-line

  // Listen for external reset toggle
  useEffect(() => {
    resetCode()
  }, [reset]) //eslint-disable-line

  // Handle input
  function handleInput(e, index) {
    const input = e.target
    const previousInput = inputRefs[index - 1]
    const nextInput = inputRefs[index + 1]

    // Update code state with single digit
    const newCode = [...code]
    // Convert lowercase letters to uppercase
    if (/^[a-z]+$/.test(input.value)) {
      const uc = input.value.toUpperCase()
      newCode[index] = uc
      inputRefs[index].current.value = uc
    } else {
      newCode[index] = input.value
    }
    setCode(newCode.join(''))

    input.select()

    if (input.value === '') {
      // If the value is deleted, select previous input, if exists
      if (previousInput) {
        previousInput.current.focus()
      }
    } else if (nextInput) {
      // Select next input on entry, if exists
      nextInput.current.select()
    }
  }

  // Select the contents on focus
  function handleFocus(e) {
    e.target.select()
  }

  // Handle backspace key
  function handleKeyDown(e, index) {
    const input = e.target
    const previousInput = inputRefs[index - 1]
    const nextInput = inputRefs[index + 1]

    if ((e.keyCode === 8 || e.keyCode === 46) && input.value === '') {
      e.preventDefault()
      setCode(
        (prevCode) => prevCode.slice(0, index) + prevCode.slice(index + 1),
      )
      if (previousInput) {
        previousInput.current.focus()
      }
    }
  }

  // Capture pasted characters
  const handlePaste = (e) => {
    const pastedCode = e.clipboardData.getData('text')
    if (pastedCode.length === 6) {
      setCode(pastedCode)
      inputRefs.forEach((inputRef, index) => {
        inputRef.current.value = pastedCode.charAt(index)
      })
    }
  }

  return (
    <div className={classes.root}>
      {[0, 1, 2, 3, 4, 5].map((index) => (
        <input
          className={classes.input}
          key={index}
          type='text'
          maxLength={1}
          onChange={(e) => handleInput(e, index)}
          ref={inputRefs[index]}
          autoFocus={index === 0}
          onFocus={handleFocus}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          disabled={isLoading}
        />
      ))}
    </div>
  )
}
