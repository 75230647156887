import { makeStyles } from 'tss-react/mui'
import React from 'react'

const animDurationMs = 200

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    transform: 'translate(-50%, -50%) scale(0.98)',
    transition: `opacity ${animDurationMs / 1000}s ease-in-out, transform ${animDurationMs / 1000}s ease-in-out`,
    opacity: 0,
  },
  main: {
    transition: `opacity ${animDurationMs / 1000}s ease-in-out`,
    opacity: 1,
  },
  mainActive: {
    opacity: 0,
  },
  overlayActive: {
    opacity: 1,
    transform: 'translate(-50%, -50%) scale(1)',
  },
}))

interface FadingOverlayProps {
  mainContent: React.JSX.Element
  overlay: React.JSX.Element
  showOverlay: boolean
  className: string
}

const FadingOverlay = ({
  mainContent,
  overlay,
  showOverlay,
  className,
}: FadingOverlayProps) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.root, className)}>
      <div className={cx(classes.main, showOverlay && classes.mainActive)}>
        {mainContent}
      </div>
      <div
        className={cx(classes.overlay, showOverlay && classes.overlayActive)}
      >
        {overlay}
      </div>
    </div>
  )
}

export default FadingOverlay
